import React, { Fragment, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { detectMobile } from '../utils'
import './style.scss'
const Titles = styled.div`
  flex:1;
  margin-top: 42px;
  font-size: 28px;
  font-weight: 500;
  color: #1A202C;
  line-height: 33px;
`
const ModalHead = styled.div`
  padding:10px 0 20px;
  display:flex;
  align-items:center;
`
const ModalWrapper = styled.div`
.network-info-client {
  padding: 0;
  margin: 20px 14px 0;
  .connect-list {
    flex-direction: column;
    margin: 0;
  }
}
.modal {
  background: #fff;
  width: 580px;
  box-shadow: 2px 3px 12px 1px rgb(96 96 96 / 35%);
  border-radius: 20px;
  padding: 0 30px 0;
  position: relative;
  animation: sliderUp .5s ease;
  &-wallet {
    width: 520px;
    .title {
      font-size: 28px;
      margin: 40px 0 60px;
    }
  }
  .search-wrapper {
    margin: 10px 50px 10px 0;
  }
    &-wrap{
      z-index: 100001;
      position: fixed;
      background: transparent;
      left:0;
      top:0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  &-close{
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px; /* px-to-viewport-ignore */
    padding: 15px;/* px-to-viewport-ignore */
    &:hover{
      opacity: .5;
    }
  }
  &-title{
    font-size: 24px;
    padding:20px 0 16px;
    margin:0;
  }
}
`
export default function ModalContainer(props) {
  // const {slider} = props
  const { visible = false,slider = false, title = null, maskClose = true, excat, ...rest } = props
  const maskRef = useRef()
  const close = (e) => {
    if (e.target === maskRef.current)  maskClose && props.onClose(false)
    e.preventDefault()
  }
  return (
    
    <Fragment>
      {
        visible &&
        ReactDOM.createPortal(
          <ModalWrapper className='modal-wrapper'>
          <div className={`mask f-c-c ${detectMobile() ? 'modal-client': ''}`}>
          <div className={slider ? `modal-wrap fixed` : `modal-wrap`} onClick={close} ref={ maskRef } >
            <div className="modal" {...rest}>
              <div className="icon-x modal-close" onClick={()=> props.onClose(false)}/>
                {
                  title &&
                <ModalHead className="f-c-sb">
                  { excat }
                  <Titles className='modal-title'>{title}</Titles>
                </ModalHead>
                }
                <div className="modal-content">
                  { props.children }
                </div>
            </div>
          </div>
        </div> </ModalWrapper>, document.querySelector('body'))
      }
    </Fragment>
   
  )
}