import styled from "styled-components"

export default function Message(props) {
  const { messageText } = props
  return (
    <MessageWrapper>
      <span className="message-text">{messageText}</span>
    </MessageWrapper>
  )
}

const MessageWrapper = styled.div`
  left: 50%;
  top: 30%;
  // transform: translate(-50%, -50%);
  z-index: 21;
  display: flex;
  position: absolute;
  background: #333;
  padding: 3px 15px;
  border-radius: 2px;
  animation: sliderUp .5s ease;
  .message-text {
    color: #fff;
    font-size: 12px;
  }
`
