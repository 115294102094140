import styled from "styled-components"
export default function PageHeader(props) {
  const {pageHeaderTitle} = props
  return(
    <PageHeaderContainer>
      <div className="title">{pageHeaderTitle}</div>
    </PageHeaderContainer>
  )
}
const PageHeaderContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
position: relative;
height: 50px;
&:after {
  content: '';
  border-bottom: 1px solid #eee;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 0;
}
`